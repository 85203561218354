import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { useList } from 'react-use'
import { media } from '../styles/utils'

import { Layout, ProductForm, Accordion, MobileSlider } from '../components'
import { container, padding, bgImage, bgIcon, type } from '../styles/global'
import { getGlobalData } from '../utils'
// import { client } from '../utils/shopify'
import { forEach } from 'lodash'

import paymentLogo1 from '../assets/images/logos/Payment-Logos-04.svg'
import paymentLogo2 from '../assets/images/logos/Payment-Logos-02.svg'
import paymentLogo3 from '../assets/images/logos/Payment-Logos-03.svg'
import paymentLogo4 from '../assets/images/logos/Payment-Logos-01.svg'

const ProductSingle = props => {
    const [data, setData] = useState(props?.pageContext)
    const [images, { set: setImages }] = useList([])
    const { global: global_data } = getGlobalData()
    const [productType, setProductType] = useState(false)
    console.log('data', data)

    useEffect(() => {
        let images_data = props?.pageContext?.images
        let images_array = []
        images_data.forEach(image => {
            let imageRewrite
            // console.log(image.src)
            if (image.originalSrc) {
                imageRewrite = image.originalSrc.replace('https://cdn.shopify.com/s','')
            }
    
            const imageSrc = imageRewrite || image?.originalSrc || image.src
            images_array.push({image: imageSrc})
        });
        images_array.shift()
        setImages(images_array)

        // fetch this products data
        // client.product
        //     .fetchByHandle(data?.handle)
        //     .then(product => {
        //         setData({
        //             ...product,
        //         })
        //         setProductType(product?.productType?.toLowerCase())
        //     })
    }, [])

    const renderImages = () => {
        if (!images) return

        const items = images.map((item, i) => {
            return (
                <Item key={i}>
                    <Image
                        key={item.image}
                        src={item.image}
                    >
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    style={{opacity: loading ? 0 : 1}}  
                                />
                            )
                        }}
                    </Image> 
                </Item>
            )
        })

        return (
            <Images>
                {items}
            </Images>
        )
    }

    const renderPaymentLogos = () => {
        const logoItems = [paymentLogo1, paymentLogo2, paymentLogo3, paymentLogo4]
        const items = logoItems.map((item, i) => {
            return (
                <Item key={i}>
                    <Icon src={item} />
                </Item>
            )
        })
        return (
            <PaymentLogos>
                {items}
            </PaymentLogos>
        )
    }

    return (
        <Layout
            headerOptions={{
                images: images.length ? images : false,
                useMobileSlider: true
            }}
            cartLink={true}
            meta={{
                title: `${data?.title} - Illoom`,
                description: data?.description,
                opengraph_image: data?.images && data?.images[0]?.originalSrc,
            }}
        >
            <MobileSlider
                slides={images.length ? images : false}
            />
            <Wrapper>
                <Container>
                    <Grid>
                        <Left>
                            <Heading>{data?.title}</Heading>
                            {data?.options && data?.variants && (
                                <ProductForm
                                    productData={data}
                                    selectedOptions={null}
                                    productType={productType}
                                />
                            )}
                        </Left>

                        <Center>
                            {renderImages()}
                        </Center>

                        <Right>
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: data?.descriptionHtml
                                }}
                            />
                            {productType === 'grillz' && global_data.moulding_kit_information && (
                                <AccordionWrap
                                    first={true}
                                >
                                    <Accordion
                                        heading={'Moulding Kit Information'}
                                        text={global_data.moulding_kit_information}
                                    />
                                </AccordionWrap>                                
                            )}
                            <AccordionWrap
                                first={productType === 'grillz' ? false : true}
                            >
                                <Accordion
                                    heading={'Shipping And Returns'}
                                    text={global_data?.shipping_and_returns}
                                />
                            </AccordionWrap>
                            {renderPaymentLogos()}
                        </Right>
                    </Grid>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Text = styled.div``
const Item = styled.div``
const Icon = styled.img``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    min-height: 100vh;
`

const Container = styled.div`
    ${container}
    ${padding}
    min-height: 100vh;
`

const Left = styled.div``
const Center = styled.div``
const Right = styled.div``

const Grid = styled.div`
    ${tw`flex`};
    min-height: 100vh;
    padding-top: 1rem;
    padding-bottom: 6rem;
    margin: 0 -1rem;

    ${media.mob`
        ${tw`flex-col mx-0`};
    `}

    ${Left}, ${Center}, ${Right} {
        ${tw`w-1/3`};
        padding: 0 1rem;

        ${media.mob`
            ${tw`w-full px-0`};
        `}
    }

    ${Left} {
        ${Heading} {
            ${type.heading}
        }
    }

    ${Center} {
        ${media.mob`
            display: none;
        `}
    }

    ${Right} {
        ${Text} {
            ${type.body}

            p:not(:first-child) {
                margin-top: 1em;
            }
        }

        ${media.mob`
            margin-top: 4rem;
        `}
    }
`

const Images = styled.div`
    ${tw`flex flex-wrap`};
    margin: -4px;

    ${Item} {
        ${tw`w-1/3`};
        padding: 4px;
    }
`

const AccordionWrap = styled.div`
    ${props => {
        if (props.first)
            return css`
                margin-top: 2rem;
            `
    }}
    ${props => {
        if (!props.first)
            return css`
                margin-top: -1px;
            `
    }}
`

const PaymentLogos = styled.div`
    display: flex;
    align-items: center;
    width: 300px;
    max-width: 100%;
    margin-top: 20px;

    ${Item} {
        &:first-child {
            ${Icon} {
                height: 20px;
                width: auto;
                margin-right: 5px;
            }
        }

        &:nth-child(2) {
            ${Icon} {
                height: 22px;
                width: auto;
                margin-top: 4px;
                margin-right: 3px;
            }
        }

        &:nth-child(3) {
            ${Icon} {
                height: 25px;
                width: auto;
                /* margin-top: 1px; */
                margin-right: 2px;
            }
        }

        &:last-child {
            ${Icon} {
                margin-top: 2px;
                height: 22px;
                width: auto;
            }
        }
    }
`

export default ProductSingle
